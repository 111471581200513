<template>
  <div
    v-loading.fullscreen.lock="loading"
    element-loading-text="加载中..."
    class="enterprise-wrap"
  >
    <div class="enterprise-head">
      <!-- <div class="w-crumbs-href">
        <router-link
          tag="span"
          :to="{ path: '/' }"
        >工作台</router-link>
        <i class="el-icon-arrow-right"></i>
        <router-link
          tag="span"
          :to="{ path: '/work' }"
        >资产管理</router-link>
        <i class="el-icon-arrow-right"></i>
        <span class="w-white">企业品牌库</span>
      </div> -->
      <div class="w-berth-btn">
        <span
          class="w-export-btn"
          @click="addEnterpriseBrand()"
          v-if="addBtn"
        ><i class="el-icon-plus"></i> 添加品牌</span>
        <span
          class="w-export-btn"
          @click="exportBrandFunc()"
          v-if="exportJurisdiction && exportJurisdiction.length > 0 && loutBtn"
        > <i class="icon-export"></i> 导出品牌</span>
        <span
          class="w-export-btn"
          @click="deleteBrandFunc()"
          v-if="delBtn"
        > <i class="el-icon-delete"></i> 删除</span>
      </div>
    </div>
    <div class="w-enterprise-screen">
      <div class="w-input-item w-input-sty">
        <span class="w-type-name">品牌名称：</span>
        <input
          class="el-input__inner"
          type="text"
          v-model="brandName"
          placeholder="请输入品牌关键字"
        />
      </div>
      <div class="w-input-item w-input-sty">
        <span class="w-type-name">品牌业态：</span>
        <el-cascader
          popper-class="black-select-panel"
          v-model="formatId"
          :options="formatList"
          :props="{ checkStrictly: true }"
          @change="changeFormat"
          clearable
        ></el-cascader>
      </div>
      <div class="w-input-item w-input-sty">
        <span class="w-type-name">合作状态：</span>
        <el-select
          popper-class="w-block-select-down"
          v-model="taskState"
          @change="changeStates"
          placeholder="请选择"
        >
          <el-option
            v-for="item in states"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div
        class="w-query-btn"
        @click="searchBtn()"
      >
        <span>查询</span>
      </div>
    </div>
    <div class="w-singed-table">
      <el-table
        :data="brandObj.dataList"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        @row-dblclick="toBrandDetail"
      >
        <el-table-column
          fixed
          type="selection"
          width="70"
        >
        </el-table-column>
        <el-table-column
          fixed
          prop="brandName"
          label="品牌"
          width="180"
        >
        </el-table-column>
        <el-table-column
          prop="gbid"
          label="品牌ID"
          width="100"
        >
        </el-table-column>
        <el-table-column
          prop="brandClass"
          label="品牌级次"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="industry"
          label="业态"
          width="220"
        >
        </el-table-column>
        <el-table-column
          prop="area"
          label="拓展面积需求"
          width="180"
        >
        </el-table-column>
        <el-table-column
          prop="taskState"
          label="状态"
          width="110"
        >
        </el-table-column>
        <el-table-column
          prop="adduser"
          label="创建人"
          width="120"
        >
        </el-table-column>
        <el-table-column
          prop="addtime"
          label="创建时间"
          width="140"
        >
        </el-table-column>
        <el-table-column
          prop="edituser"
          label="更新人"
          width="140"
        >
        </el-table-column>
        <el-table-column
          prop="editTime"
          label="更新时间"
          width="140"
        >
        </el-table-column>
        <el-table-column
          label="操作"
          width="170"
        >
          <template slot-scope="scope">
            <span @click="toBrandDetail(scope.row)">查看</span>
            <span
              v-if="editBtn"
              @click="toBrandEdit(scope.row)"
            >编辑</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="w-page-sty">
        <el-pagination
          background
          popper-class="pagination-down-class"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="brandObj.total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      loading: false,
      pageNum: 1,
      brandName: '',
      taskState: '',
      formatId: '',
      formatList: [],
      states: [{
        label: '全部',
        value: ''
      }, {
        label: '未规划',
        value: 1
      }, {
        label: '出租中',
        value: 2
      }, {
        label: '已计划落位',
        value: 3
      }, {
        label: '招商跟进中',
        value: 4
      }, {
        label: '其他业务使用中',
        value: 5
      }],
      brandObj: {},
      data: {},
      pageSize: 10,
      checkBrandList: [],
      typeName: '',
      typeTwoname: '',
      typeThreename: '',
      level: 0,
      downList: [],
      brandLibrary: [],
      exportJurisdiction: [],
      addBtn: false,
      delBtn: false,
      editBtn: false,
      loutBtn: false
    }
  },
  computed: {
    ...mapGetters({
      user: 'user'
    })
  },
  mounted () {
    this.getBrandList()
    this.getListIndustry()
    this.brandLibrary = this.user.confIds.filter(item => {
      return item.id === 164
    })
    this.addBtn = this.brandLibrary[0].func.indexOf('add') > -1
    this.delBtn = this.brandLibrary[0].func.indexOf('del') > -1
    this.editBtn = this.brandLibrary[0].func.indexOf('edit') > -1
    this.loutBtn = this.brandLibrary[0].func.indexOf('leadingout') > -1
    this.exportJurisdiction = this.user.confIds.filter(item => {
      return item.id === 180
    })
  },
  methods: {
    getBrandList () {
      this.data.pageSize = this.pageSize
      this.data.pageNum = this.pageNum
      this.axios.post(api.enterpriseBrandList, this.data)
        .then((res) => {
          this.brandObj = res.data.data
        })
    },
    handleSelectionChange (val) {
      this.checkBrandList = val
    },
    handleCurrentChange (val) {
      this.pageNum = val
      this.getBrandList()
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.getBrandList()
    },
    searchBtn () {
      this.data.brandName = this.brandName
      this.data.taskState = this.taskState
      this.data.typeName = this.typeName
      this.data.typeTwoname = this.typeTwoname
      this.data.typeThreename = this.typeThreename
      this.data.level = this.level
      this.pageNum = 1
      this.getBrandList()
    },
    changeStates () { },
    changeFormat (val) {
      this.typeName = ''
      this.typeTwoname = ''
      this.typeThreename = ''
      if (val.length > 0) {
        this.typeName = val[0]
        this.level = 1
        if (val[1]) {
          this.typeTwoname = val[1]
          this.level = 2
          if (val[2]) {
            this.typeThreename = val[2]
            this.level = 3
          } else {
            this.typeThreename = ''
            this.level = 2
          }
        } else {
          this.typeTwoname = ''
          this.level = 1
        }
      } else {
        this.level = 0
      }
    },
    toBrandEdit (item) {
      // console.log(item)
      // console.log(window.location.href)
      if (this.brandLibrary[0].func.indexOf('edit') === -1) {
        this.$message({
          type: 'warning',
          message: '抱歉，此账号暂无权限！'
        })
        return false
      } else {
        localStorage.setItem('brandId', item.brandId)
        localStorage.setItem('gbid', item.gbid)
        localStorage.setItem('brandName', item.brandName)
        if (document.domain.indexOf('local.winshangdata') > -1) {
          window.open('http://' + document.domain + ':8030/brand/#/issue/basicMsg?brandId=' + item.brandId + '&gbid=' + item.gbid + '&brandName=' + item.brandName)
        } else if (window.location.href.indexOf('http://') > -1) {
          console.log('http://' + document.domain + '/brand/#/issue/basicMsg?brandId=' + item.brandId + '&gbid=' + item.gbid + '&brandName=' + item.brandName)
          window.parent.postMessage({ changeIFrame: 'http://' + document.domain + '/brand/#/issue/basicMsg?brandId=' + item.brandId + '&gbid=' + item.gbid + '&brandName=' + item.brandName }, '*')
        } else {
          console.log('https://' + document.domain + '/brand/#/issue/basicMsg?brandId=' + item.brandId + '&gbid=' + item.gbid + '&brandName=' + item.brandName)
          window.parent.postMessage({ changeIFrame: 'https://' + document.domain + '/brand/#/issue/basicMsg?brandId=' + item.brandId + '&gbid=' + item.gbid + '&brandName=' + item.brandName }, '*')
        }
      }
    },
    addEnterpriseBrand () {
      if (this.brandLibrary[0].func.indexOf('add') === -1) {
        this.$message({
          type: 'warning',
          message: '抱歉，此账号暂无权限！'
        })
      } else {
        if (document.domain.indexOf('local.winshangdata') > -1) {
          window.open('http://' + document.domain + ':8030/brand/#/checkBrand')
        } else if (window.location.href.indexOf('http://') > -1) {
          window.parent.postMessage({ changeIFrame: 'http://' + document.domain + '/brand/#/checkBrand' }, '*')
        } else {
          window.parent.postMessage({ changeIFrame: 'https://' + document.domain + '/brand/#/checkBrand' }, '*')
        }
      }
    },
    toBrandDetail (item) {
      // const obj = this.$router.resolve({
      //   path: '/enterprise/brandDetail',
      //   query: {
      //     id: row.brandId,
      //     source: 1,
      //     questionState: 0,
      //     gbid: row.gbid
      //   }
      // })
      // window.window.href = obj.href
      if (document.domain.indexOf('local.winshangdata') > -1) {
        const obj = this.$router.resolve({
          path: '/enterprise/brandDetail',
          query: {
            brandId: item.brandId,
            source: 1,
            questionState: 0,
            gbid: item.gbid
          }
        })
        window.open(obj.href)
      } else if (window.location.href.indexOf('http://') > -1) {
        window.parent.postMessage({ changeIFrame: 'http://' + document.domain + '/#/enterprise/brandDetail?brandId=' + item.brandId + '&gbid=' + item.gbid + '&brandName=' + item.brandName }, '*')
      } else {
        window.parent.postMessage({ changeIFrame: 'https://' + document.domain + '/#/enterprise/brandDetail?brandId=' + item.brandId + '&gbid=' + item.gbid + '&brandName=' + item.brandName }, '*')
      }
    },
    deleteBrandFunc () {
      if (this.brandLibrary[0].func.indexOf('del') === -1) {
        this.$message({
          type: 'warning',
          message: '抱歉，此账号暂无权限！'
        })
        return false
      }
      if (this.checkBrandList.length > 0) {
        this.$confirm('是否确认删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true,
          iconClass: ''
        }).then(() => {
          this.loading = true
          const data = {}
          data.ids = []
          for (let i = 0; i < this.checkBrandList.length; i++) {
            data.ids.push(this.checkBrandList[i].gbid)
          }
          this.axios.post(api.deleteBrand, data).then((res) => {
            if (res.data.code === 0) {
              this.pageNum = 1
              this.getBrandList()
            }
            this.loading = false
          })
        })
      } else {
        this.$message({
          type: 'warning',
          message: '请选择要删除的品牌！'
        })
      }
    },
    exportBrandFunc () {
      if (this.exportJurisdiction.length === 0) {
        this.$message({
          type: 'warning',
          message: '抱歉，此账号暂无权限！'
        })
        return false
      }
      this.loading = true
      for (const item of this.checkBrandList) {
        const obj = {}
        obj.brandId = item.brandId
        obj.gbid = item.gbid
        this.downList.push(obj)
      }
      if (this.checkBrandList.length === 0) {
        this.downList = []
      }
      const date = new Date()
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const day = date.getDate()
      this.exportBrandsDown().then(res => { // 处理返回的文件流
        const blob = new Blob([res.data])
        const fileName = '品牌数据导出' + year + '-' + month + '-' + day + '.xlsx'
        // 兼容ie
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(blob, fileName)
          if (this.downList.length > 0) {
            if (this.brandObj.total > 20000) {
              setTimeout(() => {
                this.loading = false
              }, 60000)
            } else if (this.brandObj.total < 20000 && this.brandObj.total > 15000) {
              setTimeout(() => {
                this.loading = false
              }, 40000)
            } else if (this.brandObj.total < 15000 && this.brandObj.total > 6000) {
              setTimeout(() => {
                this.loading = false
              }, 10000)
            } else {
              this.loading = false
            }
          } else {
            this.loading = false
          }
        } else {
          const elink = document.createElement('a')
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = URL.createObjectURL(blob)
          document.body.appendChild(elink)
          elink.click()
          setTimeout(() => {
            URL.revokeObjectURL(elink.href) // 释放URL 对象
            document.body.removeChild(elink)
          }, 1000)
          if (this.downList.length > 0) {
            if (this.brandObj.total > 20000) {
              setTimeout(() => {
                this.loading = false
              }, 60000)
            } else if (this.brandObj.total < 20000 && this.brandObj.total > 15000) {
              setTimeout(() => {
                this.loading = false
              }, 40000)
            } else if (this.brandObj.total < 15000 && this.brandObj.total > 6000) {
              setTimeout(() => {
                this.loading = false
              }, 10000)
            } else {
              this.loading = false
            }
          } else {
            this.loading = false
          }
        }
      })
    },
    exportBrandsDown () {
      return this.axios({ // 用axios发送post请求
        method: 'post',
        url: api.export, // 请求地址
        data: this.downList, // 参数
        responseType: 'blob', // 表明返回服务器返回的数据类型
        headers: {
          'Content-Type': 'application/json'
        }
      })
    },
    getListIndustry () {
      this.axios.post(api.treeAll).then((res) => {
        if (res.data.code === 0) {
          this.formatList = res.data.data.map((item) => {
            if (item.children && item.children.length > 0) {
              const temObj = {
                value: item.value,
                label: item.label,
                children: item.children
              }
              temObj.children = temObj.children.map((options) => {
                if (options.children && options.children.length > 0) {
                  const temObj = {
                    value: options.value,
                    label: options.label,
                    pid: options.pid,
                    children: options.children
                  }
                  return temObj
                } else {
                  const temObj = {
                    value: options.value,
                    label: options.label,
                    pid: options.pid
                  }
                  return temObj
                }
              })
              return temObj
            } else {
              return item
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.enterprise-wrap
  // padding 10px 23px
  .enterprise-head
    display flex
    height 34px
    .w-berth-btn
      margin-left auto
      span
        text-align center
        display inline-block
        line-height 34px
      .w-export-btn
        overflow hidden
        height 33px
        width 120px
        border 1px solid rgba(255, 255, 255, 0.5)
        border-radius 3px
        font-size 14px
        color rgba(255, 255, 255, 1)
        margin-left 20px
        cursor pointer
        .icon-export
          margin-right 7px
          font-size 14px
        .icon-add:before
          font-size 14px
  .w-enterprise-screen
    height 135px
    background #272930
    box-shadow 0px 6px 12px 0px rgba(0, 0, 0, 0.04)
    border-radius 3px
    margin-top 11px
    padding-left 40px
    box-sizing border-box
    display flex
    align-items center
    .w-input-item
      margin-right 56px
      input
        font-size 14px
      .w-type-name
        display inline-block
        margin-right 4px
        color #fff
        font-size 14px
        font-weight 400
  .w-singed-table
    background #272930
    box-shadow 0px 6px 12px 0px rgba(0, 0, 0, 0.04)
    border-radius 3px
    margin-top 15px
    box-sizing border-box
    padding 0 24px
    padding-bottom 35px
    table
      width 100%
      thead
        tr
          height 54px
          line-height 54px
          color #999
          font-size 12px
          td
            border-bottom 1px solid rgba(216, 216, 216, 0.1)
            padding-left 10px
      tbody
        tr
          height 47px
          line-height 47px
          color #ffffff
          font-size 12px
          &:hover td
            background-color #23252b
          td
            border-bottom 1px solid rgba(216, 216, 216, 0.1)
            padding-left 10px
.cell
  span
    color #ffa134
    margin 0 5px
    cursor pointer
</style>
